import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import emailjs from '@emailjs/browser';
import Notiflix from 'notiflix';

const DemoProduct = () => {
    // Set page title
    useDocTitle('Yagya InfoTech | Demo our products');

    // State management
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        school_management: false,
        event_management: false,
    });
    const [errors, setErrors] = useState({});

    // Clear errors
    const clearErrors = () => setErrors({});

    // Reset form
    const resetForm = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: '',
            school_management: false,
            event_management: false,
        });
    };

    // Validation function
    const validateForm = (selectedProducts) => {
        const newErrors = {};
        if (!formData.first_name.trim()) newErrors.first_name = 'First Name is required.';
        if (!formData.last_name.trim()) newErrors.last_name = 'Last Name is required.';
        if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
            newErrors.email = 'Valid Email is required.';
        if (!formData.phone.trim() || !/^\d+$/.test(formData.phone))
            newErrors.phone = 'Valid Phone Number is required.';
        if (!formData.message.trim()) newErrors.message = 'Message is required.';
        if (selectedProducts.length === 0)
            newErrors.products = 'At least one product must be selected.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Submit handler using EmailJS
    const sendEmail = async (e) => {
        e.preventDefault();

        // Disable the button and show a loading state
        const submitBtn = document.getElementById('submitBtn');
        submitBtn.disabled = true;
        submitBtn.textContent = 'Sending...';

        // Collect the selected products
        const selectedProducts = [];
        if (formData.school_management) selectedProducts.push('School Management Portal');
        if (formData.event_management) selectedProducts.push('Facial Attendence System');

        // Validate the form
        if (!validateForm(selectedProducts)) {
            submitBtn.disabled = false;
            submitBtn.textContent = 'Send Message';
            return;
        }

        try {
            // Send the email via EmailJS
            await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID, // Replace with your EmailJS service ID
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Replace with your EmailJS template ID
                {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    email: formData.email,
                    phone_number: formData.phone,
                    message: `I want the demo of ${selectedProducts.join(', ')},${formData.message}`,
                    // selected_products: selectedProducts.join(', '),
                },
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Replace with your EmailJS public key
            );

            // Show success notification and reset form
            Notiflix.Report.success(
                'Success',
                'Your message has been sent successfully!',
                'Okay'
            );
            resetForm();
        } catch (error) {
            // Show error notification
            Notiflix.Report.failure(
                'An error occurred',
                'Failed to send the message. Please try again later.',
                'Okay'
            );
            console.error('EmailJS Error:', error);
        } finally {
            // Re-enable the button and reset its text
            submitBtn.disabled = false;
            submitBtn.textContent = 'Send Message';
        }
    };

    return (
        <>
            {/* Navbar */}
            <NavBar />

            {/* Main Form Section */}
            <div id="demo" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20">
                    <form onSubmit={sendEmail} id="demoProductForm" className="bg-white shadow-lg rounded-lg p-8">
                        <h1 className="font-bold text-blue-900 text-3xl mb-6 text-center">Demo Our Products</h1>

                        {/* Product Selection */}
                        <div className="mb-4">
                            <div className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    value="school_management"
                                    checked={formData.school_management}
                                    onChange={(e) =>
                                        setFormData({ ...formData, school_management: e.target.checked })
                                    }
                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="ml-2 text-gray-700">School Management Portal</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    value="event_management"
                                    checked={formData.event_management}
                                    onChange={(e) =>
                                        setFormData({ ...formData, event_management: e.target.checked })
                                    }
                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="ml-2 text-gray-700">Facial Attendance System</label>
                            </div>
                            {errors.products && <p className="text-red-500 text-sm mt-2">{errors.products}</p>}
                        </div>

                        {/* Form Fields */}
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <input
                                    type="text"
                                    name="first_name"
                                    placeholder="First Name*"
                                    value={formData.first_name}
                                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                />
                                {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="last_name"
                                    placeholder="Last Name*"
                                    value={formData.last_name}
                                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                />
                                {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-4">
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email*"
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Phone*"
                                    value={formData.phone}
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                />
                                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                            </div>
                        </div>

                        <div className="my-4">
                            <textarea
                                name="message"
                                placeholder="Message*"
                                value={formData.message}
                                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline h-32"
                            ></textarea>
                            {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                        </div>

                        <button
                            type="submit"
                            id="submitBtn"
                            className="w-full bg-blue-900 text-white p-3 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    );
};

export default DemoProduct;
