import React from 'react';
import webImg from '../images/web.svg';
import appImg from '../images/app.svg';
import hostingImg from '../images/hosting.svg';
import consultImg from '../images/consultation.svg';

const Services = () => {
    const serviceCards = [
        {
            img: webImg,
            title: 'Web Development',
            description: 'Creating custom, mobile-friendly websites for businesses of all sizes.'
        },
        {
            img: appImg,
            title: 'Mobile App Development',
            description: 'Developing high-quality, cross-platform mobile applications.'
        },
        {
            img: hostingImg,
            title: 'Domain and Hosting',
            description: 'Providing domain registration and web hosting services.'
        },
        {
            img: consultImg,
            title: 'IT Consultations',
            description: 'Expert guidance to optimize technology solutions.'
        }
    ];

    return (
        <div className="bg-gray-100 py-12">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl text-blue-900 uppercase font-bold mb-4">Services</h2>
                
                <div className="grid md:grid-cols-4 gap-4 px-4">
                    {serviceCards.map((service, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-lg p-4 hover:bg-gray-500 hover:text-white transition">
                            <img src={service.img} alt={service.title} className="mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>

                <div className="mt-12 text-center">
                    <div className="flex justify-center space-x-8">
                        <div>
                            <h3 className="text-2xl text-blue-900 font-bold">
                                We <span className="text-orange-500">Build</span>
                            </h3>
                            <p className="text-gray-600 mt-4">
                                Providing customized IT solutions with 2+ years of experience in software analysis and design.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl text-blue-900 font-bold">
                                We <span className="text-orange-500">Collaborate</span>
                            </h3>
                            <p className="text-gray-600 mt-4">
                                Scaling existing software and designing custom solutions to simplify your processes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;